import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { BarChart, Bar } from "recharts";
import Translation from "utils/Translation";

const LineChartComponent_simple = ({ data }) => {
    const tooltipFormatter = (value, name) => {
        const unit = "€";
        return [`${value} ${unit}`, name];
    };

    // Vérifie s'il y a des valeurs non nulles pour costs et adjustment dans les données
    const hasCosts = data.some((item) => item.costs && item.costs !== 0);
    const hasAdjustment = data.some(
        (item) => item.adjustment && item.adjustment !== 0
    );

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip formatter={tooltipFormatter} />
                <Legend />

                <Bar
                    dataKey="energySaved"
                    stackId="a"
                    fill="#346840"
                    name={<Translation tag={"Energie_euro"} />}
                    isAnimationActive={false}
                />
                <Bar
                    dataKey="waterSaved"
                    stackId="a"
                    fill="#106198"
                    name={<Translation tag={"Eau_euro"} />}
                    isAnimationActive={false}
                />

                {/* Affiche les barres costs et adjustment seulement s'il y a des valeurs */}
                {hasCosts && (
                    <Bar
                        dataKey="costs"
                        fill="#A31621"
                        name={<Translation tag={"Couts_euro"} />}
                        isAnimationActive={false}
                    />
                )}
                {hasAdjustment && (
                    <Bar
                        dataKey="adjustment"
                        fill="#84DB00"
                        name={<Translation tag={"Regularisation_euro"} />}
                        isAnimationActive={false}
                    />
                )}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default LineChartComponent_simple;
