import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import Translation from "utils/Translation";
import RoomCard from "component/Roomcard";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";

const Rooms = () => {
    const keycloak = useAuth();
    const { hId } = useContext(HotelContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedFloor, setSelectedFloor] = useState("");
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const fetchRoomsHotel = () => {
        setLoading(true);
        fetch(`/api/hotels/${hId}/rooms`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === "OK") {
                    setRooms(data.rooms);
                    setHasError(false);
                } else {
                    setHasError(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (keycloak.isAuthenticated && hId) {
            fetchRoomsHotel();
        }
    }, [keycloak.isAuthenticated, hId]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredRooms = rooms
        .filter((room) => room.rRoomNumber.includes(searchTerm))
        .filter(
            (room) =>
                selectedFloor === "" ||
                Number(room.rFloor) === Number(selectedFloor)
        );

    const essentialAndExperienceRooms = filteredRooms.filter(
        (room) => room.rTag === "essentiel" || room.rTag === "experience"
    );

    const temoinRooms = filteredRooms.filter((room) => room.rTag === "temoin");

    return (
        <div className="rooms">
            <BreadCrumb text="Rooms_title" />
            <h1 className="h1-page">
                <Translation tag={"Rooms_title"} />
            </h1>
            <h2 className="h2-page">
                <Translation tag={"Rooms_description"} />
            </h2>

            <div>
                {essentialAndExperienceRooms.length === 0 ? (
                    <Translation tag={"No_rooms"} />
                ) : (
                    essentialAndExperienceRooms.map((room) => (
                        <RoomCard
                            key={room.rId}
                            number={String(room.rRoomNumber).padStart(2, "0")}
                            floor={
                                Number(room.rFloor) === 0
                                    ? "0"
                                    : room.rFloor < 10
                                    ? `0${Number(room.rFloor)}`
                                    : room.rFloor
                            }
                            tag={room.rTag}
                            waterSavings={
                                room.total_water_savings
                                    ? Math.round(
                                          room.total_water_savings
                                      ).toLocaleString("fr-FR")
                                    : "0"
                            }
                            moneySavings={
                                room.total_money_savings
                                    ? Math.round(
                                          room.total_money_savings
                                      ).toLocaleString("fr-FR")
                                    : "0"
                            }
                            // link="/" // Ajustez le lien si nécessaire
                        />
                    ))
                )}
            </div>

            <h2 className="h2-page">
                <Translation tag={"Rooms_description_temoin"} />
            </h2>

            <div>
                {temoinRooms.length === 0 ? (
                    <Translation tag={"No_rooms"} />
                ) : (
                    temoinRooms.map((room) => (
                        <RoomCard
                            key={room.rId}
                            number={String(room.rRoomNumber).padStart(2, "0")}
                            floor={
                                Number(room.rFloor) === 0
                                    ? "0"
                                    : room.rFloor < 10
                                    ? `0${Number(room.rFloor)}`
                                    : room.rFloor
                            }
                            tag={room.rTag}
                            waterSavings={"0"}
                            moneySavings={"0"}
                            // link="/" // Ajustez le lien si nécessaire
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default Rooms;
