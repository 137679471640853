import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import Translation from "utils/Translation";
import LineChartComponent from "component/Linecomponent";
import StatCard_simple from "component/Stat_card_simple";
import SmallCard from "component/small_card";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";

const Economies = () => {
    const keycloak = useAuth();
    const { hId } = useContext(HotelContext);

    const [tempStartDate, setTempStartDate] = useState("");
    const [tempEndDate, setTempEndDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [isStartDateManuallySet, setIsStartDateManuallySet] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [stats, setStats] = useState({
        numberOfShowers: 0,
        totalWaterSaved: 0,
        totalEnergySaved: 0,
        averageObjectives: 0,
        percentObjectives: 0,
    });

    const [showers, setShowers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const fetchShowersHotel = async () => {
        console.log("Fetching hotel showers...");
        setLoading(true);
        try {
            const response = await fetch(`/api/hotels/${hId}/showers`, {
                method: "get",
                headers: {
                    Authorization: `Bearer ${keycloak.user.access_token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            if (data.success === "OK") {
                setShowers(data.showers);
                setHasError(false);
            } else {
                setHasError(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // console.log(keycloak);
        // on lance que si on a un user et un hotel
        // On relance la fonction quand on a une modification de keycloak (un user authentifié)
        if (keycloak.isAuthenticated && keycloak.user && hId) {
            fetchShowersHotel();
        }
    }, [keycloak.isAuthenticated, hId]);

    useEffect(() => {
        if (showers.length > 0 && !isStartDateManuallySet) {
            const initialStartDate = showers.reduce((minDate, shower) => {
                const showerDate = shower.shTimeCreate?.split("T")[0];
                return showerDate && (!minDate || showerDate < minDate)
                    ? showerDate
                    : minDate;
            }, showers[0]?.shTimeCreate?.split("T")[0] || new Date().toISOString().split("T")[0]);

            setTempStartDate(initialStartDate);
        }

        const standardShowerLitres = 102;
        const standardShowerTemp = 40;

        const filteredData = showers.filter((shower) => {
            const showerDate = new Date(shower.shTimeCreate?.split(" ")[0]);
            return (
                (!startDate || showerDate >= new Date(startDate)) &&
                (!endDate || showerDate <= new Date(endDate))
            );
        });

        let waterSavings = 0;
        let energySavings = 0;
        let objectivesCount = 0;
        let objectivesTotal = 0;

        const monthlySums = {};
        filteredData.forEach((shower) => {
            const showerDate = new Date(shower.shTimeCreate?.split(" ")[0]);
            if (isNaN(showerDate)) return; // Ignore invalid dates

            const monthKey = `${showerDate.getFullYear()}-${String(
                showerDate.getMonth() + 1
            ).padStart(2, "0")}`;

            if (!monthlySums[monthKey]) {
                monthlySums[monthKey] = { waterSaved: 0, energySaved: 0 };
            }

            const showerWaterSaving =
                standardShowerLitres - (shower.shCONSO_DOUCHE || 0);
            const showerEnergySaving =
                (standardShowerLitres * (standardShowerTemp - 12) * 1.161) /
                    1000 -
                ((shower.shCONSO_DOUCHE || 0) *
                    ((shower.shTEMP_DOUCHE || 12) - 12) *
                    1.161) /
                    1000;

            monthlySums[monthKey].waterSaved += showerWaterSaving;
            monthlySums[monthKey].energySaved += showerEnergySaving;

            waterSavings += showerWaterSaving;
            energySavings += showerEnergySaving;

            if (shower.shOBJ_DOUCHE > 0) {
                objectivesCount++;
                objectivesTotal += shower.shOBJ_DOUCHE;
            }
        });

        const averageObjectives =
            objectivesCount > 0 ? objectivesTotal / objectivesCount : 0;
        const percentObjectives =
            objectivesCount > 0
                ? (objectivesCount / filteredData.length) * 100
                : 0;

        const chartDataWithSums = Object.entries(monthlySums)
            .map(([month, sums]) => ({
                month,
                waterSaved: Math.round(sums.waterSaved),
                energySaved: Math.round(sums.energySaved),
            }))
            .sort(
                (a, b) => new Date(`${a.month}-01`) - new Date(`${b.month}-01`)
            );

        setChartData(chartDataWithSums);
        setStats({
            numberOfShowers: filteredData.length,
            totalWaterSaved: Math.round(waterSavings),
            totalEnergySaved: Math.round(energySavings),
            averageObjectives: Math.round(averageObjectives),
            percentObjectives: Math.round(percentObjectives),
        });
    }, [showers, startDate, endDate]);

    const handleApplyDates = () => {
        if (tempStartDate && tempEndDate) {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
            setIsStartDateManuallySet(true);
        }
    };

    return (
        <div className="economies">
            <BreadCrumb text="Eco_title" />
            <div className="Room_dateTitle">
                <h1 className="h1-page">
                    <Translation tag="Eco_title" />
                </h1>
                <div className="date-picker">
                    <span>
                        <Translation tag="Period" />
                    </span>
                    <input
                        type="date"
                        className="date_style"
                        value={tempStartDate}
                        onChange={(e) => {
                            setTempStartDate(e.target.value);
                            setIsStartDateManuallySet(true);
                        }}
                    />
                    <span>
                        <Translation tag="To" />
                    </span>
                    <input
                        type="date"
                        className="date_style"
                        value={tempEndDate}
                        onChange={(e) => setTempEndDate(e.target.value)}
                    />
                    <button
                        className="button_valid_date"
                        onClick={handleApplyDates}
                    >
                        <Translation tag="validate" />
                    </button>
                </div>
            </div>
            <div className="Dashboard-part-2-bis">
                <div className="Chart_dashboard">
                    <h1 className="Title_graph">
                        <Translation tag="Savings_period" />
                    </h1>
                    <LineChartComponent
                        data={chartData}
                        Temp="Energie"
                        Eau="Eau"
                        TempName="energySaved"
                        EauName="waterSaved"
                        XAxis="month"
                    />
                </div>
                <div className="stat_simple">
                    <StatCard_simple
                        number={stats.totalWaterSaved.toLocaleString("fr-FR")}
                        text1="L"
                        text1Color="#deecf7"
                    />
                    <StatCard_simple
                        number={Math.round(stats.totalEnergySaved)}
                        text1="kWh"
                        text1Color="#f0f7de"
                    />
                    <StatCard_simple
                        number={Math.round(
                            stats.totalEnergySaved * 0.2 +
                                (stats.totalWaterSaved / 1000) * 4
                        )}
                        text1="€"
                        text1Color="#f7eede"
                    />
                </div>
            </div>
            <h1 className="h1-page">
                <Translation tag="Room_stat" />
            </h1>
            <div className="col_dashboard_number">
                <SmallCard
                    title="Number_Shower"
                    value={stats.numberOfShowers.toLocaleString("fr-FR")}
                    unit=""
                />
                <SmallCard
                    title="Percent_obj"
                    value={stats.percentObjectives.toLocaleString("fr-FR")}
                    unit="%"
                />
                <SmallCard
                    title="Average_obj"
                    value={stats.averageObjectives.toLocaleString("fr-FR")}
                    unit="L"
                />
            </div>
        </div>
    );
};

export default Economies;
