import React, { useState, useEffect } from 'react';
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg"
import cloud from "../img/Cloud.svg"
import money from "../img/money.svg"
import StatCard from "component/Stat_card";
import DonationCard from 'component/Donation_card';
import bdd_don from "../BDD/bdd_don.json";
import BreadCrumb from 'component/Breadcrumb';
import Recap_xp from 'component/Recap_xp';
import Avis from 'component/Avis';
import signaMarque from '../BDD/signa_marque.png';
import signa_marque_blanche_ess from '../BDD/signa_marque_blanche_ess.png'

const Customer_xp = () => {
    const [stats, setStats] = useState({
      numberOfShowers: 0,
      totalWaterSaved: 0,
    });

    const formatDate = (dateString) => {
      const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('fr-FR', options);
    };
    const sortedDonations = bdd_don.sort((a, b) => new Date(b.dDeliveryDate) - new Date(a.dDeliveryDate));
    const totalDonations = sortedDonations.length;
    const totalWaterGiven = sortedDonations.reduce((acc, donation) => acc + donation.dWaterGive, 0);


  return <>
  <div className='Customer_xp'>
    <BreadCrumb text="Customer_xp" />
    <h1 className="h1-page">
      <Translation tag={"Customer_xp_title"} />
    </h1>
    <h2 className="h2-page">
      <Translation tag={"avis_clients"} />
    </h2>

    <div style={{ display: "flex", flexWrap: "wrap", gap: "5%" }}>
    <Avis
    Name={"Claclas 11"}
    note={"3/5"}
    date={"13/05/2024"}
    site={"booking.com"}
    Description={"L'hôtel était très propre, le système de la douche pour évaluer sa consommation d'eau était très ludique. Le petit déjeuner était bien. Enorme bémol concernant la literie, le matelas était très fin et posé sur une planche en bois donc dur."}
    link={"https://www.booking.com/"}
    />
     <Avis
    Name={"Emanuele Napoli"}
    note={"5/5"}
    date={"2/02/2024"}
    site={"Google"}
    Description={"Nous avons séjourné dans la planet friendly room donc à vocation verte et avons passé un très bon moment. Par exemple, vous connaissez votre consommation d'eau. Vraiment top ! "}
    link={"https://www.google.com/"}
    />
     <Avis
    Name={"Biofa_TV"}
    note={"5/5"}
    date={"13/05/2020"}
    site={"TripAdvisor"}
    Description={"La chambre est très belle, l'idée d'une douche où l'on peut choisir les litres d'eau à utiliser est merveilleuse, le personnel est très gentiel et super serviable !"}
    link={"https://www.tripadvisor.com/"}
    />
    </div>

    <h2 className="h2-page" style={{marginTop: "5%" }}>
      <Translation tag={"Rooms_typology"} />
    </h2>
     <Recap_xp
    defaultObjective={"40L"}
    proposedObjectives={"30L - 50L - 80L"}
    Download_title={"Guide Expérience"}
    onDownloadClick={"https://dae.gouv.nc/sites/default/files/atoms/files/modeletype_cgv_ccpc.pdf"}
    image={signaMarque}
    Title = {"Experience"}
    />
    <Recap_xp
    defaultObjective={"40L"}
    proposedObjectives={""}
    Download_title={"Guide Essentiel"}
    onDownloadClick={"https://dae.gouv.nc/sites/default/files/atoms/files/modeletype_cgv_ccpc.pdf"}
    image={signa_marque_blanche_ess}
    Title = {"Essentiel"}
    />
  </div>
  </>;
};

export default Customer_xp;
