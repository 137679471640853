import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import BreadCrumb from "component/Breadcrumb";
import Translation from "utils/Translation";
import LineChartComponent_axe from "component/Linecomponent_axe";
import StatCard from "component/Stat_card";
import { useAuth } from "react-oidc-context";

const Consommation = () => {
    const keycloak = useAuth();
    const { hId } = useContext(HotelContext);
    const [shower, setShower] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [isStartDateManuallySet, setIsStartDateManuallySet] = useState(false);

    const [tempStartDate, setTempStartDate] = useState("");
    const [tempEndDate, setTempEndDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [chartData, setChartData] = useState([]);
    const [temperatureData, setTemperatureData] = useState([]);
    const [stats, setStats] = useState({
        temoinAverage: 0,
        experienceAverage: 0,
        essentielAverage: 0,
        temoinAverageTemp: 0,
        experienceAverageTemp: 0,
        essentielAverageTemp: 0,
    });

    const fetchShowersHotel = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/hotels/${hId}/showers`, {
                method: "get",
                headers: {
                    Authorization: `Bearer ${keycloak.user.access_token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            if (data.success === "OK" && Array.isArray(data.showers)) {
                setShower(data.showers);
                setHasError(false);
            } else {
                setHasError(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchRoomsHotel = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/hotels/${hId}/rooms`, {
                method: "get",
                headers: {
                    Authorization: `Bearer ${keycloak.user.access_token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            if (data.success === "OK") {
                setRooms(data.rooms);
                setHasError(false);
            } else {
                setHasError(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // console.log(keycloak);
        // on lance que si on a un user et un hotel
        if (keycloak.isAuthenticated && keycloak.user && hId) {
            fetchShowersHotel();
            fetchRoomsHotel();
        }
    }, [keycloak.isAuthenticated, hId]);

    useEffect(() => {
        const joinedData = shower
            .map((shower) => {
                const room = rooms.find((room) => room.rId === shower.shRId);

                if (!room || !shower.shTimeCreate) return null;

                const showerDate = new Date(shower.shTimeCreate);
                if (isNaN(showerDate.getTime())) return null;

                return {
                    ...shower,
                    roomTag: room.rTag,
                    shTimeCreate: showerDate,
                };
            })
            .filter((entry) => entry !== null);

        const filteredData = joinedData.filter((data) => {
            const showerDate = data.shTimeCreate;
            return (
                (!tempStartDate || showerDate >= new Date(tempStartDate)) &&
                (!tempEndDate || showerDate <= new Date(tempEndDate))
            );
        });

        const result = filteredData.reduce(
            (acc, record) => {
                const date = record.shTimeCreate;
                const month = `${date.getFullYear()}-${String(
                    date.getMonth() + 1
                ).padStart(2, "0")}`;

                if (!acc.monthlyData[month]) {
                    acc.monthlyData[month] = {
                        month,
                        temoinTotal: 0,
                        temoinCount: 0,
                        temoinTotalTemp: 0,
                        experienceTotal: 0,
                        experienceCount: 0,
                        experienceTotalTemp: 0,
                        essentielTotal: 0,
                        essentielCount: 0,
                        essentielTotalTemp: 0,
                    };
                }

                if (record.roomTag === "temoin") {
                    acc.global.temoinTotal += record.shCONSO_DOUCHE;
                    acc.global.temoinCount += 1;
                    acc.global.temoinTotalTemp += record.shTEMP_DOUCHE;

                    acc.monthlyData[month].temoinTotal += record.shCONSO_DOUCHE;
                    acc.monthlyData[month].temoinCount += 1;
                    acc.monthlyData[month].temoinTotalTemp +=
                        record.shTEMP_DOUCHE;
                } else if (record.roomTag === "experience") {
                    acc.global.experienceTotal += record.shCONSO_DOUCHE;
                    acc.global.experienceCount += 1;
                    acc.global.experienceTotalTemp += record.shTEMP_DOUCHE;

                    acc.monthlyData[month].experienceTotal +=
                        record.shCONSO_DOUCHE;
                    acc.monthlyData[month].experienceCount += 1;
                    acc.monthlyData[month].experienceTotalTemp +=
                        record.shTEMP_DOUCHE;
                } else if (record.roomTag === "essentiel") {
                    acc.global.essentielTotal += record.shCONSO_DOUCHE;
                    acc.global.essentielCount += 1;
                    acc.global.essentielTotalTemp += record.shTEMP_DOUCHE;

                    acc.monthlyData[month].essentielTotal +=
                        record.shCONSO_DOUCHE;
                    acc.monthlyData[month].essentielCount += 1;
                    acc.monthlyData[month].essentielTotalTemp +=
                        record.shTEMP_DOUCHE;
                }

                return acc;
            },
            {
                global: {
                    temoinTotal: 0,
                    temoinCount: 0,
                    temoinTotalTemp: 0,
                    experienceTotal: 0,
                    experienceCount: 0,
                    experienceTotalTemp: 0,
                    essentielTotal: 0,
                    essentielCount: 0,
                    essentielTotalTemp: 0,
                },
                monthlyData: {},
            }
        );

        const temoinAverage =
            result.global.temoinCount > 0
                ? Math.round(
                      result.global.temoinTotal / result.global.temoinCount
                  )
                : 0;
        const experienceAverage =
            result.global.experienceCount > 0
                ? Math.round(
                      result.global.experienceTotal /
                          result.global.experienceCount
                  )
                : 0;
        const essentielAverage =
            result.global.essentielCount > 0
                ? Math.round(
                      result.global.essentielTotal /
                          result.global.essentielCount
                  )
                : 0;

        const temoinAverageTemp =
            result.global.temoinCount > 0
                ? Math.round(
                      result.global.temoinTotalTemp / result.global.temoinCount
                  )
                : 0;
        const experienceAverageTemp =
            result.global.experienceCount > 0
                ? Math.round(
                      result.global.experienceTotalTemp /
                          result.global.experienceCount
                  )
                : 0;
        const essentielAverageTemp =
            result.global.essentielCount > 0
                ? Math.round(
                      result.global.essentielTotalTemp /
                          result.global.essentielCount
                  )
                : 0;

        setStats({
            temoinAverage,
            experienceAverage,
            essentielAverage,
            temoinAverageTemp,
            experienceAverageTemp,
            essentielAverageTemp,
        });

        const consumptionData = Object.values(result.monthlyData).map(
            (monthData) => ({
                month: monthData.month,
                temoinAverage:
                    monthData.temoinCount > 0
                        ? Math.round(
                              monthData.temoinTotal / monthData.temoinCount
                          )
                        : 0,
                experienceAverage:
                    monthData.experienceCount > 0
                        ? Math.round(
                              monthData.experienceTotal /
                                  monthData.experienceCount
                          )
                        : 0,
                essentielAverage:
                    monthData.essentielCount > 0
                        ? Math.round(
                              monthData.essentielTotal /
                                  monthData.essentielCount
                          )
                        : 0,
            })
        );

        const temperatureData = Object.values(result.monthlyData).map(
            (monthData) => ({
                month: monthData.month,
                temoinAverageTemp:
                    monthData.temoinCount > 0
                        ? Math.round(
                              monthData.temoinTotalTemp / monthData.temoinCount
                          )
                        : 0,
                experienceAverageTemp:
                    monthData.experienceCount > 0
                        ? Math.round(
                              monthData.experienceTotalTemp /
                                  monthData.experienceCount
                          )
                        : 0,
                essentielAverageTemp:
                    monthData.essentielCount > 0
                        ? Math.round(
                              monthData.essentielTotalTemp /
                                  monthData.essentielCount
                          )
                        : 0,
            })
        );

        setChartData(consumptionData);
        setTemperatureData(temperatureData);
    }, [shower, rooms, tempStartDate, tempEndDate]);

    const handleApplyDates = () => {
        if (tempStartDate && tempEndDate) {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
        }
    };

    return (
        <div className="consommation">
            <BreadCrumb text="Conso_title" />
            <h1 className="h1-page">
                <Translation tag="Conso_title" />
            </h1>

            {isLoading ? (
                <div>Chargement...</div>
            ) : hasError ? (
                <div>Erreur de chargement des données.</div>
            ) : (
                <>
                    <div className="date-picker">
                        <span>
                            <Translation tag="Period" />
                        </span>
                        <input
                            type="date"
                            className="date_style"
                            value={tempStartDate}
                            onChange={(e) => {
                                setTempStartDate(e.target.value);
                                setIsStartDateManuallySet(true);
                            }}
                        />
                        <span>
                            <Translation tag="To" />
                        </span>
                        <input
                            type="date"
                            className="date_style"
                            value={tempEndDate}
                            onChange={(e) => setTempEndDate(e.target.value)}
                        />
                        <button
                            className="button_valid_date"
                            onClick={handleApplyDates}
                        >
                            <Translation tag="validate" />
                        </button>
                    </div>

                    <h1 className="h1-page">
                        <Translation tag="Eau" />
                    </h1>
                    <div className="Dashboard-part-2-bis">
                        <LineChartComponent_axe
                            data={chartData}
                            Temp="Temoin"
                            Eau="Essentiel"
                            TempName="temoinAverage"
                            EauName="essentielAverage"
                            Exp="experienceAverage"
                            Exp_name="Experience"
                            XAxis="month"
                        />
                    </div>

                    <div className="col_dashboard_number">
                        <StatCard
                            number={stats.temoinAverage.toLocaleString("fr-FR")}
                            text1="L"
                            text1Color="#FFFFFF"
                            text2="moyenne_temoin"
                        />
                        <StatCard
                            number={stats.essentielAverage.toLocaleString(
                                "fr-FR"
                            )}
                            text1="L"
                            text1Color="#FFFFFF"
                            text2="moyenne_essentiel"
                        />
                        <StatCard
                            number={stats.experienceAverage.toLocaleString(
                                "fr-FR"
                            )}
                            text1="L"
                            text1Color="#FFFFFF"
                            text2="moyenne_experience"
                        />
                    </div>

                    <h1 className="h1-page">
                        <Translation tag="Temperature" />
                    </h1>
                    <div className="Dashboard-part-2-bis">
                        <LineChartComponent_axe
                            data={temperatureData}
                            Temp="Temoin"
                            Eau="Essentiel"
                            TempName="temoinAverageTemp"
                            EauName="essentielAverageTemp"
                            Exp="experienceAverageTemp"
                            Exp_name="Experience"
                            XAxis="month"
                        />
                    </div>

                    <div className="col_dashboard_number">
                        <StatCard
                            number={stats.temoinAverageTemp.toLocaleString(
                                "fr-FR"
                            )}
                            text1="°C"
                            text1Color="#FFFFFF"
                            text2="moyenne_temoin"
                        />
                        <StatCard
                            number={stats.essentielAverageTemp.toLocaleString(
                                "fr-FR"
                            )}
                            text1="°C"
                            text1Color="#FFFFFF"
                            text2="moyenne_essentiel"
                        />
                        <StatCard
                            number={stats.experienceAverageTemp.toLocaleString(
                                "fr-FR"
                            )}
                            text1="°C"
                            text1Color="#FFFFFF"
                            text2="moyenne_experience"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Consommation;
