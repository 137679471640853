import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import ContenerSaaS from "utils/ContenerSaaS";

const GroupInfos = (props) => {
    const { keycloak, initialized } = useKeycloak();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // TO DO: get les infos du groupe avec l'ID

    return (
        <ContenerSaaS title="Mon groupe" notMaxHeight>
            <div>
                {!keycloak.isAuthenticated ? (
                    <div>Pour voir vos accès, il faut se connecter</div>
                ) : isLoading ? (
                    <div>Chargement...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    <></>
                )}
            </div>
        </ContenerSaaS>
    );
};

export default GroupInfos;
