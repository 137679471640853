import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import Slidebar from "../component/Slidebar";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg";
import cloud from "../img/Cloud.svg";
import money from "../img/money.svg";
import LineChartComponent_simple from "component/Linecomponent_simple";
import LineChartComponent from "component/Linecomponent";

import StatCard from "component/Stat_card";
import { Col, Row } from "react-bootstrap";
import bdd from "../BDD/bdd.json";
import { Link } from "react-router-dom";
import BreadCrumb from "component/Breadcrumb";
import CertificateCard from "component/certificatecard";
import bdd_don from "../BDD/bdd_don.json";
import { useAuth } from "react-oidc-context";

const Dashboard = () => {
    const keycloak = useAuth();

    const { hId } = useContext(HotelContext);

    const formatDate = (dateString) => {
        const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
        return new Date(dateString).toLocaleDateString("fr-FR", options);
    };

    const handleDownload = () => {
        console.log("Téléchargement du certificat...");
        // Implémentez la logique de téléchargement ici
    };

    const [chartData, setChartData] = useState([]);
    const [stats, setStats] = useState({
        numberOfShowers: 0,
        totalWaterSaved: 0,
        totalEnergySaved: 0,
    });

    const [showers, setShowers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [luniones, setLuniones] = useState([]);
    const [reduction, setReduction] = useState(null);
    const [monthlyData, setMonthlyData] = useState([]);

    const currentDate = new Date(); // Obtenez la date actuelle
    currentDate.setFullYear(currentDate.getFullYear() - 1); // Décrémentez l'année de 1

    const [startDate, setStartDate] = useState(
        currentDate.toISOString().split("T")[0]
    );
    const [endDate, setEndDate] = useState(
        new Date().toISOString().split("T")[0]
    );

    const setStartFromBeginning = () => {
        // Définir la date de début à une date très ancienne
        setStartDate(new Date(2000, 0, 1).toISOString().split("T")[0]);
    };

    const setStartFromYearStart = () => {
        // Définir la date de début au début de l'année en cours
        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        setStartDate(startOfYear.toLocaleDateString("en-CA")); // Format YYYY-MM-DD pour la compatibilité HTML
    };

    const setStartFromQuarterStart = () => {
        // Définir la date de début au début du trimestre en cours
        const now = new Date();
        const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
        const quarterStart = new Date(now.getFullYear(), quarterStartMonth, 1);
        setStartDate(quarterStart.toLocaleDateString("en-CA")); // Format YYYY-MM-DD pour la compatibilité HTML
    };

    const fetchShowersHotel = () => {
        // const hId = localStorage.getItem("hId");
        console.log("Fetching hotel showers...");
        setLoading(true);
        fetch(`/api/hotels/${hId}/showers`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Received shower data - ", data);
                if (data.success === "OK" && Array.isArray(data.showers)) {
                    setShowers(data.showers);
                    setHasError(false);
                } else {
                    setHasError(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setHasError(true);
                setLoading(false);
            });
    };
    const fetchRoomsHotel = () => {
        // const hId = localStorage.getItem("hId");
        console.log("Fetching hotel showers...");
        setLoading(true);
        fetch(`/api/hotels/${hId}/rooms`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Received rooms data - ", data);
                if (data.success === "OK") {
                    setRooms(data.rooms);
                    setHasError(false);
                } else {
                    setHasError(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setHasError(true);
                setLoading(false);
            });
    };

    // const fetchLunionesHotel = () => {
    //     // const hId = localStorage.getItem("hId");
    //     console.log("Fetching hotel luniones...");
    //     setLoading(true);
    //     fetch(`/api/luniShower/${hId}/luniOnes`, {
    //         method: "get",
    //         headers: {
    //             Authorization: "Bearer " + keycloak.user.access_token,
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             console.log("Received luniones data - ", data);
    //             if (data.success === "OK") {
    //                 setLuniones(data.luniOnes);
    //                 setHasError(false);
    //             } else {
    //                 setHasError(true);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching data:", error);
    //             setHasError(true);
    //             setLoading(false);
    //         });
    // };

    useEffect(() => {
        // console.log(keycloak);
        // on lance que si on a un user et un hotel
        if (keycloak.isAuthenticated && keycloak.user && hId) {
            fetchShowersHotel();
            // fetchLunionesHotel();
            fetchRoomsHotel();
        }
    }, [keycloak.isAuthenticated, startDate, endDate, hId]);

    useEffect(() => {
        if (showers.length && rooms.length) {
            calculateReduction();
        }
    }, [showers, rooms, luniones, startDate, endDate]);

    const calculateReduction = () => {
        // Join tables by matching product numbers and room IDs
        console.log("Dashboard - premier test")
        const joinedData = showers
            .map((shower) => {
                const lUnion = luniones.find(
                    (l) => l.lNumProduct === shower.shNUM_PRODUIT
                );
                const room = lUnion
                    ? rooms.find((r) => r.rId === lUnion.lRId)
                    : null;
                return room
                    ? {
                          ...shower,
                          roomTag: room.Tag,
                          shTimeCreate: shower.shTimeCreate,
                      }
                    : null;
            })
            .filter((entry) => entry !== null);

        const filteredData = joinedData.filter((data) => {
            const showerDate = new Date(data.shTimeCreate);
            return (
                (!startDate || showerDate >= new Date(startDate)) &&
                (!endDate || showerDate <= new Date(endDate))
            );
        });

        // Separate data into 'temoin' and 'non-temoin' groups
        const temoinData = filteredData.filter(
            (data) => data.roomTag === "temoin"
        );
        const nonTemoinData = filteredData.filter(
            (data) => data.roomTag !== "temoin"
        );

        // Calculate average consumption for each group
        const temoinAvg = calculateAverage(temoinData);
        const nonTemoinAvg = calculateAverage(nonTemoinData);

        // Calculate reduction percentage
        const reductionPercentage = Math.round(
            ((nonTemoinAvg - temoinAvg) / temoinAvg) * 100
        );
        setReduction(reductionPercentage);
        console.log("Dashboard - reduction :", reduction)
    };

    const calculateAverage = (dataArray) => {
        const total = dataArray.reduce(
            (sum, data) => sum + data.shCONSO_DOUCHE,
            0
        );
        return dataArray.length ? total / dataArray.length : 0;
    };

    useEffect(() => {
        const standardShowerLitres = 102;
        const standardShowerTemp = 40;
        let waterSavings = 0;
        let energySavings = 0;

        // Filtre les données entre les dates de début et de fin
        const filteredData = showers.filter((sh) => {
            const showerDate = new Date(sh.shTimeCreate);
            return (
                (!startDate || showerDate >= new Date(startDate)) &&
                (!endDate || showerDate <= new Date(endDate))
            );
        });

        // Calculer les économies d'eau et d'énergie totales
        waterSavings = filteredData.reduce(
            (sum, record) =>
                sum + (standardShowerLitres - record.shCONSO_DOUCHE),
            0
        );
        energySavings = filteredData.reduce(
            (sum, record) =>
                sum +
                (standardShowerLitres * (standardShowerTemp - 12) * 1.161) /
                    1000 -
                (record.shCONSO_DOUCHE * (record.shTEMP_DOUCHE - 12) * 1.161) /
                    1000,
            0
        );

        // Regrouper les données pour le graphique par semaine
        const monthlySavings = filteredData.reduce((acc, record) => {
            const date = new Date(record.shTimeCreate);
            const month = `${date.getFullYear()}-${date.getMonth() + 1}`; // Format "2023-9" pour différencier mois et années

            if (!acc[month]) {
                acc[month] = { month, waterSaved: 0, energySaved: 0 };
            }

            const waterSaved =
                ((standardShowerLitres - record.shCONSO_DOUCHE) / 1000) * 4;
            const energySaved =
                0.2 *
                ((standardShowerLitres * (standardShowerTemp - 12) * 1.161) /
                    1000 -
                    (record.shCONSO_DOUCHE *
                        (record.shTEMP_DOUCHE - 12) *
                        1.161) /
                        1000);

            acc[month].waterSaved += waterSaved;
            acc[month].energySaved += energySaved;

            return acc;
        }, {});

        // Calculer les moyennes pour chaque mois
        const result = Object.values(monthlySavings)
            .map((monthData) => ({
                month: monthData.month,
                waterSaved: Math.round(monthData.waterSaved),
                energySaved: Math.round(monthData.energySaved),
            }))
            .sort(
                (a, b) =>
                    new Date(`01 ${a.month} 2000`) -
                    new Date(`01 ${b.month} 2000`)
            );
        setMonthlyData(result);

        // Met à jour les données du graphique et les statistiques
        setStats({
            numberOfShowers: filteredData.length,
            totalWaterSaved: Math.round(waterSavings),
            totalEnergySaved: Math.round(energySavings),
        });
    }, [showers, startDate, endDate]);

    return (
        <>
            <div className="Dashboard">
                <BreadCrumb text="Dashboard_title" />
                <h1 className="h1-page">
                    <Translation tag={"Dashboard_title"} />
                </h1>
                <div className="button-container">
                    <button
                        className="more-button"
                        onClick={setStartFromBeginning}
                    >
                        <Translation tag={"since_beginning"} />{" "}
                    </button>
                    <button
                        className="more-button"
                        onClick={setStartFromYearStart}
                    >
                        <Translation tag={"since_year"} />{" "}
                    </button>
                    <button
                        className="more-button"
                        onClick={setStartFromQuarterStart}
                    >
                        <Translation tag={"since_quarter"} />{" "}
                    </button>
                </div>
                <div className="col_dashboard_number">
                    <StatCard
                        number={stats.totalWaterSaved.toLocaleString("fr-FR")}
                        text1="L"
                        text1Color="#FFFFFF"
                        text2="Water_savings"
                        icon={droplet}
                    >
                        {" "}
                    </StatCard>
                    <StatCard
                        number={reduction}
                        text1="%"
                        text1Color="#FFFFFF"
                        text2="perc_savings"
                        icon={cloud}
                    >
                        {" "}
                    </StatCard>
                    <StatCard
                        number={Math.round(
                            stats.totalEnergySaved * 0.2 +
                                (stats.totalWaterSaved / 1000) * 4
                        ).toLocaleString("fr-FR")}
                        text1="€"
                        text1Color="#FFFFFF"
                        text2="Money_savings"
                        icon={money}
                    >
                        {" "}
                    </StatCard>
                </div>
                <div className="Dashboard-part-2">
                    <div className="Chart_dashboard">
                        <div className="chart-header">
                            <h1 className="Title_graph">
                                <Translation tag={"Savings_period"} />
                            </h1>
                            <Link to="/hotel/lunishower/economies">
                                <button className="more-button">
                                    <Translation tag={"See_more"} />{" "}
                                    <span className="more-arrow">→</span>
                                </button>
                            </Link>
                        </div>
                        <LineChartComponent_simple data={monthlyData} />
                    </div>

                    <div className="Donation">
                        <div className="chart-header">
                            <h1 className="Title_graph">
                                <Translation tag={"donation_title"} />
                            </h1>
                            <Link to="/hotel/lunishower/donation">
                                {/* <button className="more-button-2">
                                    <span className="more-arrow-2">→</span>
                                </button> */}
                            </Link>
                        </div>
                        {bdd_don.length > 0 ? (
                            bdd_don.slice(0, 3).map((don) => (
                                <CertificateCard
                                    certificateNumber={don.dNumber.toString()}
                                    dateIssued={formatDate(don.dDeliveryDate)}
                                    // onDownloadClick={don.dlink}
                                />
                            ))
                        ) : (
                            <p>
                                <Translation tag={"No_donation"} />
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
