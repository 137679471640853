// import { useAuth } from "react-oidc-context";

const Replicated = async (keycloak, sethId) => {
    console.log("Replicated - Welcome to replicated");

    if (!keycloak.isAuthenticated || !keycloak.user) {
        return "notoken";
    }

    const token = keycloak.user.access_token;
    const tokenParsed = keycloak.user.profile;

    try {
        const userResponse = await fetch("/api/users/me", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        const userData = await userResponse.json();

        if (!userData.user) {
            // Vérifier si le compte de l'utilisateur a été supprimé
            if (userData.message === "Account deleted") {
                return "deleted";
            }
            // Si l'utilisateur n'existe pas, le créer
            await ask_create_user(token, tokenParsed);

            // Suppression du check des invitations pour l'instant
            // await checkInvitations(token);
            return "created";
        } else {
            // Mettre à jour l'utilisateur existant
            await update_user(token, tokenParsed);

            // Suppression du check des invitations pour l'instant
            // await checkInvitations(token);

            // Si l'utilisateur n'a pas terminé son inscription
            if (!userData.user.uBirthDate || !userData.user.uJob) {
                return "created";
            } else {
                sethId(userData.user.uLastHotel);
                return "updated";
            }
        }
    } catch (err) {
        console.error("Replicated - Error fetching user data:", err);
        throw err;
    }
};

const checkInvitations = async (token) => {
    console.log("Replicated - Check invitations");

    try {
        const response = await fetch("/api/hotels/invitations/invitedUserId", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });

        const responseData = await response.json();
        console.log("Replicated - Invitations response:", responseData);
    } catch (err) {
        console.error("Replicated - Error checking invitations:", err);
    }
};

const ask_create_user = async (token, tokenParsed) => {
    console.log("Replicated - Creating user...");
    try {
        const response = await fetch("/api/users/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                uId: tokenParsed.sub,
                name: tokenParsed.given_name,
                surname: tokenParsed.family_name,
                mail: tokenParsed.email,
                birthDate: null,
                job: null,
            }),
        });
        const resData = await response.json();
        console.log("Replicated - User creation response :", resData);
    } catch (err) {
        console.error("Replicated - Error creating user:", err);
    }
};

const update_user = async (token, tokenParsed) => {
    console.log("Replicated - Updating user...");
    try {
        const response = await fetch("/api/users/lastConnexion", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const resData = await response.json();
        console.log("Replicated - User update response:", resData);
    } catch (err) {
        console.error("Replicated - Error updating user:", err);
    }
};

export default Replicated;
