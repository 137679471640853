import React from 'react';
import { Link } from 'react-router-dom';
import Translation from 'utils/Translation';
import CertificateCard from './certificatecard';
import Download from './Download';

function Recap_xp(props) {
    return (
        <div className="Recap" class="recap_container">
        <div class="recap_textSection">
            <h2 class="recap_title">      
                <Translation tag={props.Title} />
            </h2>
            
            <div class="Recap_objective">
                <p> <Translation tag={"Default_obj"} /></p>
                <p class="recap_defaultObjective">{props.defaultObjective}</p>
            </div>

            {props.proposedObjectives && (
            <div className="recap_proposedObjectives">
                <p><Translation tag={"Propose_screen"} /></p>
                <p className="recap_objectiveValues">{props.proposedObjectives}</p>
            </div>
            )}
             <div class="recap_resources">
                <p><Translation tag={"Ressource"} /></p>
                <Download title={props.Download_title} onClick={props.onDownloadClick} date="" />
            </div> 
        </div>
        
         <div class="recap_imageSection">
            <img src={props.image} alt="Signalétique en chambre" class="recap_image" />
            <p class="recap_imageCaption"><Translation tag={"Room_signage"} /></p> 
        </div>
    </div>
    )
}

export default Recap_xp;
