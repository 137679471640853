// LineChartComponent.js
import React, { useState, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Translation from 'utils/Translation';
import { HotelContext } from 'context/HotelContext';

const LineChartComponent_axe = (props) => {
  const { lang } = useContext(HotelContext);

  let minTemp = undefined;
  let maxTemp = undefined;
  let minConso = undefined;
  let maxConso = undefined;

// Parcourez toutes les entrées de données pour trouver les valeurs min et max si les données existent
props.data.forEach(entry => {
  if (entry && entry[props.TempName] !== undefined) {
    if (minTemp === undefined || entry[props.TempName] < minTemp) {
      minTemp = entry[props.TempName];
    }
    if (maxTemp === undefined || entry[props.TempName] > maxTemp) {
      maxTemp = entry[props.TempName];
    }
  }
  if (entry && entry[props.EauName] !== undefined) {
    if (minConso === undefined || entry[props.EauName] < minConso) {
      minConso = entry[props.EauName];
    }
    if (maxConso === undefined || entry[props.EauName] > maxConso) {
      maxConso = entry[props.EauName];
    }
  }
});

let lastMonth = null;

const formatToMonthUnique = (dateString) => {
  const date = new Date(dateString);
  const month = new Intl.DateTimeFormat(lang, { month: 'short' }).format(date);

    if (month === lastMonth) {
      return ""; // Ne rien afficher si le mois est le même que le précédent
    } else {
      lastMonth = month;
      return month;
    }
  };
  
  return (
    <ResponsiveContainer width="100%" height={300}>

      <LineChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis 
          dataKey={props.XAxis || "Date"}
          //tickFormatter={formatToMonthUnique}
        />
        <Tooltip />
        <YAxis/>
        <Legend />
         <Line type="monotone" name={<Translation tag={props.Temp}/>} dataKey={props.TempName} stroke="#106198" strokeWidth={2} dot={false} />
         <Line type="monotone" name={<Translation tag={props.Eau}/>} dataKey={props.EauName} stroke="#365644"  strokeWidth={2} dot={false}/>
         <Line type="monotone" name={<Translation tag={props.Exp_name}/>} dataKey={props.Exp} stroke="#90B87B"  strokeWidth={2} dot={false}/>

      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent_axe;
