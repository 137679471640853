/*
 * Formulaire pour la création et la modification des hôtels
 */

import { useAuth } from "react-oidc-context";
import React, { useContext, useEffect, useId, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContenerAction from "utils/ContenerAction";
import InfoForm from "utils/InfoForm";
import Notation from "utils/Notation";

import { HotelContext } from "context/HotelContext";
import InfoFeature from "./InfoFeature";
import ConfirmationDeleteUserHotel from "views/ConfirmationDeleteUserHotel";

function InfoAccesses(props) {
    const keycloak = useAuth();
    const { hId } = useContext(HotelContext);

    const [reponseAccessHotel, setReponseAccessHotel] = useState([]);
    const [reponseAccessFeatures, setReponseAccessFeatures] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasReponse, setHasReponse] = useState(false);

    const [features, setFeatures] = useState(props.accessFeatures);

    function saveNewAcces(features) {
        setLoading(true);
        console.log(features);
        let featureHotel = {};
        let accessFeatures = [];

        features.forEach((element, ind) => {
            if (element.fId === 0) {
                featureHotel = element;
            } else {
                element.featureRights.forEach((right) => {
                    accessFeatures.push({
                        frId: right.frId,
                        value: right.value,
                    });
                });
            }
        });

        console.log();

        let featureHotelToModify = {
            ahModify: null,
            ahDelete: null,
            ahGiveAccessHotel: null,
        };

        featureHotel.featureRights.forEach((element) => {
            if (element.frId === 1) {
                featureHotelToModify.ahModify = element.value;
            } else if (element.frId === 3) {
                featureHotelToModify.ahDelete = element.value;
            } else if (element.frId === 2) {
                featureHotelToModify.ahGiveAccessHotel = element.value;
            }
        });

        console.log(featureHotelToModify);

        const link =
            "/api/hotels/" + hId + "/accessHotels/" + props.collabEditable;
        console.log(link);
        fetch(link, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToGive: featureHotelToModify,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                setReponseAccessHotel(res);
                //ne pas faire le reste
                if (res.success == "OK") {
                    setAccessFeatures(accessFeatures);
                }
                if (res.success == "NO") {
                    setHasReponse(true);
                    //setHasError(true);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setHasReponse(true);
                setHasError(true);
                setLoading(false);
            });
    }

    function setAccessFeatures(accessFeatures) {
        console.log(accessFeatures);
        const link =
            "/api/hotels/" + hId + "/accessFeatures/" + props.collabEditable;
        console.log(link);
        fetch(link, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessFeatures: accessFeatures,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                setHasError(false);
                setLoading(false);
                setReponseAccessFeatures(res);
                setHasReponse(true);
            })
            .catch((err) => {
                setHasReponse(true);
                setHasError(true);
                setLoading(false);
            });
    }

    //console.log(features);
    return (
        <div>
            {!keycloak.isAuthenticated ? (
                <div>
                    Pour voir les accès d'un collaborateur, il faut se connecter
                </div>
            ) : isLoading ? (
                <div>Chargement...</div>
            ) : hasError ? (
                <div>Une erreur </div>
            ) : (
                <div>
                    {hasReponse ? (
                        // @ts-ignore
                        <InfoForm
                            status={
                                hasError
                                    ? "NOK"
                                    : reponseAccessHotel.success == "NO"
                                    ? "NOK"
                                    : "OK"
                            }
                            reponse={reponseAccessHotel}
                        />
                    ) : (
                        <></>
                    )}
                    Gestion des acces
                    {features.map((feature) => (
                        <InfoFeature
                            feature={feature}
                            key={"features" + feature.fId}
                            accessType={props.accessType}
                        ></InfoFeature>
                    ))}
                    {props.accessType === "userAccess" ? ( //gerer le loading ici avec les réponses
                        <div>
                            <Link
                                to="/settings/hotel/collaborators"
                                onClick={() => {
                                    props.setCollabEditable(null);
                                }}
                            >
                                <input
                                    type="button"
                                    className="btnLwe btn btn-primary btnCancel"
                                    value="Annuler"
                                />
                            </Link>
                            <Button onClick={() => saveNewAcces(features)}>
                                Modifier les accès
                            </Button>
                            <Link
                                to="."
                                onClick={() => props.setWantDelete(true)}
                            >
                                Supprimer la visibilité de cet hôtel à cette
                                personne
                            </Link>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>
    );
}

// InfoAccesses.propTypes = {
//     accessFeatures: PropTypes.arrayOf(
//         PropTypes.shape({
//             fId: PropTypes.number,
//             featureRights: PropTypes.arrayOf(
//                 PropTypes.shape({
//                     frId: PropTypes.number,
//                     value: PropTypes.number,
//                 })
//             ),
//         })
//     ),
//     collabEditable: PropTypes.string,
//     accessType: PropTypes.string,
//     setCollabEditable: PropTypes.number,
// };

export default InfoAccesses;
