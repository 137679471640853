/* 
@author : adumoulin
*/

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, useLocation, Navigate } from "react-router-dom";

// Mes imports
import Home from "../views/Home";

import Slidebar from "../component/Slidebar";
import Slidebar_settings from "../component/Slidebar_settings";

import Navbar from "../utils/Navbar";
import Reglages from "./Reglages";
import { HotelContext } from "../context/HotelContext";
import ChoiceHotel from "./ChoiceHotel";
import AjouterHotel from "./AjouterHotel";
import PersonalSettings from "./PersonalSettings";
import MyAccesses from "./MyAccesses";
import Collaborators from "./Collaborators";
import UserAccess from "./UserAccess";
import AddGroup from "./AddGroup";
import ContenerSaaS from "utils/ContenerSaaS";
import Inscription from "./Inscription";
import Logo from "component/Logo";
import Dashboard from "./Dashboard";
import Consommation from "./consommation";
import Economies from "./economies";
import Rooms from "./rooms";
import Donation from "./donation";
import Room_overview from "./Room_overview";
import { useAuth } from "react-oidc-context";
import Translation from "utils/Translation";
import Customer_xp from "./Customer_xp";

const Base = (props) => {
    const { hId, accessRights } = useContext(HotelContext);
    const location = useLocation();
    const path = location.pathname;
    const hotelIsSelected = hId ? true : false;
    const keycloak = useAuth();

    return (
        <div className="App">
            {props.viewID == "inscription" ? (
                <Inscription></Inscription>
            ) : (
                <>
                    <Row className="Row_Content">
                        <Navbar
                        // pageName={props.pageName}
                        // hotelName={"Aucun hôtel"}
                        />
                        {path.includes("/user/")|| !hotelIsSelected ?  (
                            <Col xs="3" className="sidebarBloc2">
                                <Slidebar_settings viewID={props.viewID} path={path} />
                            </Col>
                        ) :
                        path.includes("/hotel") || path.includes("/settings")? (
                            <Col xs="3" className="sidebarBloc">
                                <Slidebar viewID={props.viewID} path={path} />
                            </Col>
                        ) : (
                            <></>
                        )}

                            {hId ? (
                                <Col xs="9" className="mainBloc">
                                <div className="mainContent">
                                    {props.viewID == "dashboard" ? (
                                        <Dashboard></Dashboard>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "consommation" ? (
                                        <Consommation></Consommation>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "economies" ? (
                                        <Economies></Economies>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "room" ? (
                                        <Room_overview></Room_overview>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "rooms" ? (
                                        <Rooms></Rooms>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "donation" && !accessRights?.RSE ? (
                                        <Navigate to="/hotel/lunishower/dashboard" replace />
                                    ) : (
                                        props.viewID == "donation" && <Donation />
                                    )}
                                    {/* {props.viewID == "donation" ? (
                                        <Donation></Donation>
                                    ) : (
                                        <></>
                                    )} */}
                                    {props.viewID == "main" ? (
                                        <Home></Home>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "hotelInformations" ? (
                                        <Reglages></Reglages>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "myAccess" ? (
                                        <Home></Home>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "customer_xp" && !accessRights.RSE ? (
                                        <Navigate to="/hotel/lunishower/dashboard" replace />
                                    ) : (
                                        props.viewID == "customer_xp" && <Customer_xp />
                                    )}
                                    {props.viewID == "rooms_settings" ? (
                                        <Home></Home>
                                    ) : (
                                        <></>
                                    )}
                                    {props.viewID == "devices" ? (
                                        <Home></Home>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                </Col>
                            ) : (
                                <Col xs="10" className="mainBloc">
                                <div className="mainContent">                                    
                                    {props.viewID == "personalInformations" ? (
                                        <>
                                        <PersonalSettings />
                                        </>
                                    ) : props.viewID == "addHotel" ? (
                                        <AjouterHotel />
                                    ) : props.viewID == "myHotels" ? (
                                        <ChoiceHotel />
                                    ) : props.viewID == "addGroup" ? (
                                        <ContenerSaaS
                                            title="Créer un groupe"
                                            notMaxHeight
                                        >
                                            <AddGroup type={"create"} />
                                        </ContenerSaaS>
                                    ) : (
                                        <Translation tag="Connection_required_Hotel" />
                                        // <Veuillez vous connecter pour accéder à notre plateforme.</>
                                    )}
                                </div>
                                </Col>
                            )}
                    </Row>
                </>
            )}
        </div>
    );
};

export default Base;
