/*
 * Formulaire pour la création et la modification des hôtels
 */
import React, { useContext, useEffect, useId, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContenerAction from "utils/ContenerAction";
import InfoForm from "utils/InfoForm";
import Notation from "utils/Notation";
import { useAuth } from "react-oidc-context";
import { HotelContext } from "context/HotelContext";
import ConfirmationDeleteAccount from "views/ConfirmationDeleteAccount";
import Translation from "utils/Translation";

function FormUser(props) {
    const keycloak = useAuth();

    // Initialisation des champs avec des valeurs par défaut si `props.user` est vide ou non défini
    const [name, setName] = useState(props.user?.uName || "");
    const [surname, setSurname] = useState(props.user?.uSurname || "");
    const [mail, setMail] = useState(props.user?.uMail || "");
    const [birthDate, setBirthDate] = useState(props.user?.uBirthDate || "");
    const [job, setJob] = useState(props.user?.uJob || "");

    //const [isNewHotel, setIsNewHotel] = useState(false)
    const [reponse, setReponse] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasReponse, setHasReponse] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [wantDelete, setWantDelete] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (
            isValidEmail &&
            mail !== "" &&
            name !== "" &&
            surname !== "" &&
            birthDate !== "" &&
            job !== ""
        ) {
            try {
                await saveReponse(); // Call the saveReponse function

                // Mettre à jour le profil de l'utilisateur dans Keycloak si supporté
                if (keycloak.account?.update) {
                    keycloak.account.update({
                        firstName: name,
                        lastName: surname,
                    });
                }

                // Déclencher le rechargement dans Navbar après la mise à jour
                props.setReload(true);

            } catch (error) {
                setHasError(true);
                setHasReponse(true);
                setReponse({ message: "Une erreur s'est produite lors de la sauvegarde" });
            }
        } else {
            setReponse({
                message: "Un des champs est vide ou l'email est incorrect",
            });
            setHasReponse(true);
            setHasError(true);
        }
    };

    // Validation du format de l'email
    const validateEmail = (value) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setIsValidEmail(false);
        } else {
            setIsValidEmail(true);
        }
    };

    // Fonction pour sauvegarder la réponse (mise à jour des informations utilisateur)
    const saveReponse = async () => {
        setLoading(true);
        const link = "/api/users/";

        try {
            const response = await fetch(link, {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + keycloak.user.access_token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    surname: surname,
                    mail: mail,
                    birthDate: birthDate,
                    job: job,
                    visible: 1,
                }),
            });

            const res = await response.json();

            if (res.error !== undefined) {
                setHasError(true);
                setHasReponse(true);
                setReponse(res);
            } else {
                setHasError(false);
                setReponse(res);
                setHasReponse(true);

                 // Effacer la réponse après 3 secondes
                setTimeout(() => setHasReponse(false), 3000);
            }
        } catch (err) {
            setHasReponse(true);
            setHasError(true);
            setReponse({ message: "Erreur lors de la mise à jour des informations" });
        } finally {
            setLoading(false);
        }
    };


    // Formater la date de naissance initiale lors du chargement du formulaire
    useEffect(() => {
        if (props.user?.uBirthDate) {
            const initialDate = new Date(props.user?.uBirthDate);
            const formattedDate = initialDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            setBirthDate(formattedDate);
        }
    }, [props.user?.uBirthDate, keycloak.isAuthenticated]);

    return isLoading ? (
        <div><Translation tag={"Saving"}></Translation></div>
    ) : !wantDelete ? (
            <Form className="form formLwe" onSubmit={handleSubmit}>
                {hasReponse ? (
                    <InfoForm status={hasError ? "NOK" : "OK"} reponse={reponse} />
                ) : (
                    <></>
                )}
                <FormGroup className="border_bottom">
                    <Row className="oneFormInput">
                        <Col xs="4" className="left">
                        <div className="email-container">
                            <label htmlFor="exampleEmail" className="form-label"><Translation tag={"Identifiant"}></Translation></label>
                            <label htmlFor="exampleEmail" className="form-label text-muted small"><Translation tag={"Email"}/></label>
                        </div>
                        </Col>
                        <Col xs="8" className="form-input">
                            <input
                                name="mail"
                                value={mail}
                                className="lweInput"
                                onBlur={() => validateEmail(mail)}
                                onChange={(e) => setMail(e.target.value)}
                                id="mail"
                                placeholder="contact@luniwave.com"
                                autoComplete="none"
                                disabled
                            />
                        </Col>
                    </Row>
                    {!isValidEmail && (
                        <p style={{ color: "red" }}><Translation tag={"Email_invalid"}/></p>
                    )}
                </FormGroup>
                <FormGroup className="border_bottom">
                    <Row className="oneFormInput">
                        <Col xs="4" className="left">
                        <div className="email-container">
                            <label htmlFor="exampleEmail" className="form-label"><Translation tag={"Informations"}/></label>
                            <label htmlFor="exampleEmail" className="form-label text-muted small"><Translation tag={"About_you"}/></label>
                        </div>
                        </Col>
                        <Col xs="8" className="left">
                        <label htmlFor="exampleEmail" className="form-label text-muted small"><Translation tag={"First_name"}/></label>
                            <div className="form-input">
                                <input
                                    name="name"
                                    value={name}
                                    className="lweInput"
                                    onChange={(e) => setName(e.target.value)}
                                    id="name"
                                    placeholder="Charlotte"
                                />
                            </div>
                            <label htmlFor="exampleEmail" className="form-label text-muted small"><Translation tag={"Last_name"}/></label>
                            <div className="form-input">
                                <input
                                    name="surname"
                                    value={surname}
                                    className="lweInput"
                                    onChange={(e) => setSurname(e.target.value)}
                                    id="surname"
                                    placeholder="Leroy"
                                />
                            </div>
                            <label htmlFor="exampleEmail" className="form-label text-muted small"><Translation tag={"birthdate"}/></label>
                            <div className="form-input">
                            <input
                                type="date"
                                name="birthDate"
                                value={birthDate}
                                className="lweInput"
                                onChange={(e) => setBirthDate(e.target.value)}
                                id="birthDate"
                                placeholder="2002-09-23"
                            />
                            </div>
                            <label htmlFor="exampleEmail" className="form-label text-muted small"><Translation tag={"Job_title"}/></label>
                            <div className="form-input">
                            <input
                                name="job"
                                value={job}
                                className="lweInput"
                                onChange={(e) => setJob(e.target.value)}
                                id="job"
                                placeholder="Sustainable Development Manager"
                            />
                            </div>
                        </Col>
                    </Row>
                    </FormGroup>
                    <ContenerAction className="Bouton_form">
                        <Button className="bouton_annuler" onClick={() => props.setReload(true)}>
                        <Translation tag={"Cancel"}/>
                        </Button>
                        <input
                            type="submit"
                            className="bouton_valider"
                            value="Enregistrer"
                            // disabled
                        />
                       {/*  <input
                            type="button"
                            className="btnLwe btn btn-primary"
                            value="Supprimer"
                            onClick={() => setWantDelete(true)}
                        /> */}
                    </ContenerAction>
                
            </Form>
    ) : (
        <ConfirmationDeleteAccount
            name={name}
            surname={surname}
            mail={mail}
            birthDate={birthDate}
            job={job}
            setWantDelete={setWantDelete}
        ></ConfirmationDeleteAccount>
    );
}

// FormUser.propTypes = {
//     user: PropTypes.shape({
//         uName: PropTypes.string,
//         uSurname: PropTypes.string,
//         uMail: PropTypes.string,
//         uBirthDate: PropTypes.string,
//         uJob: PropTypes.string,
//     }),
// };

export default FormUser;
