import React, { createContext, useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";

const HotelContext = createContext();

const HotelContextProvider = ({ children }) => {
    const keycloak = useAuth();

    // the value that will be given to the context
    const [hId, sethId] = useState(() => localStorage.getItem("hId") || null);
    const [lang, setLang] = useState(
        () => localStorage.getItem("lang") || "fr"
    );
    const [accessRights, setAccessRights] = useState(() => JSON.parse(localStorage.getItem("access")) || null);

    useEffect(() => {
        console.log("Changement de hID : ", hId);
        if (keycloak.isAuthenticated && hId != null) {
            console.log("On set item, retrieved ");
            localStorage.setItem("hId", hId);
        } else {
            if (!keycloak.isLoading) {
                console.log("On supprimer le localstorage ");
                localStorage.removeItem("hId");
                sethId(null);
            }
        }

        if (hId == null && localStorage.getItem("hId") != null) {
            console.log(
                "Récupération de hId depuis le localStorage : ",
                localStorage.getItem("hId")
            );
            sethId(localStorage.getItem("hId"));
        }
    }, [keycloak.isLoading, hId]);

    useEffect(() => {
        const fetchAccessRights = async () => {
            console.log("HotelContext - Tentative de récupération des droits d'accès")
            try {
                const response = await fetch(`/api/luniShower/${hId}/rights`, {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + keycloak.user.access_token,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Droits récupérés : ", data);
                    setAccessRights(data);
                    localStorage.setItem("access", JSON.stringify(data));
                } else {
                    console.log("test")
                    console.log(response)
                    console.log("Erreur lors de la récupération des droits d'accès");
                }
            } catch (e) {
                console.error("Erreur lors de la récupération de l'information", e);
            }
        };

        if (keycloak.isAuthenticated && hId) {
            fetchAccessRights();
        } else {
            setAccessRights(null);
            localStorage.removeItem("access");
        }
    }, [keycloak.isAuthenticated, hId]);



    // fetch a user from a fake backend API
    // useEffect(() => {
    //     const fetchHotel = () => {
    //         console.log(keycloak.token)
    //         const uid = keycloak.tokenParsed.sub
    //         fetch('/api/gerants/' + uid + '/hotels', {
    //             method: 'get',
    //             headers: {
    //                 Authorization: 'Bearer ' + keycloak.token,
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //             .then((response) => {
    //                 console.log(response)
    //                 return response.json()
    //             })
    //             .then((data) => {
    //                 console.log(data)
    //                 // setd(1)
    //             })
    //             .catch((error) => console.log('An error occured'))
    //     }

    //     // const

    //     keycloak.isAuthenticated ? fetchHotel() : sethId(null)
    // }, [initialized])

    return (
        // the Provider gives access to the context to its children
        <HotelContext.Provider value={{ hId, sethId, lang, setLang, accessRights }}>
            {children}
        </HotelContext.Provider>
    );
};

export { HotelContext, HotelContextProvider };
