import React, { useState } from "react";
import { Link } from "react-router-dom";
import bed from "../img/bed.svg";
import Translation from "utils/Translation";

function RoomCard(props) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        // <Link
        //     to={`/hotel/lunishower/rooms/${props.number}`}
        //     className="room-card"
        // >
        <div className="room-card">
            <div className="room-icon">
                {" "}
                <img src={bed} alt="bed"></img>
            </div>
            <div className="RoomcardElem">
                <span className="TopRoomCardText">
                    N°
                    <br />
                </span>
                <span className="BottomRoomCardText">{props.number}</span>
            </div>
            <div className="RoomcardElem">
                <span className="TopRoomCardText">
                    <Translation tag={"Room_floor"} />
                    <br />
                </span>
                <span className="BottomRoomCardText">{props.floor}</span>
            </div>
            <div className="RoomcardElem">
                <span className="TopRoomCardText">
                    Tag
                    <br />
                </span>
                <span className="BottomRoomCardText">{props.tag}</span>
            </div>
            <div className="RoomcardElem">
                <span className="TopRoomCardText">
                    <Translation tag={"Room_savings"} />
                    <br />
                </span>
                <span className="BottomRoomCardText">
                    {Math.round(
                        Number(props.waterSavings.replace(/\s/g, "")) / 10
                    ) * 10}{" "}
                    L
                </span>
            </div>
            <div className="RoomcardElem">
                <span className="TopRoomCardText">
                    <Translation tag={"Gain"} />
                    <br />
                </span>
                <span className="BottomRoomCardText">
                    {props.moneySavings} €
                </span>
            </div>

            {/* {isHovered 
                ? <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABn0lEQVR4nO2Wy07CQBSG5yVceIG4dKErXYkvYKLG51DEZ3Gn0bhw6R7lTUTac6ouNFzmgJXOlIjRDFYlSqczBSIL/mSSbpqv/38uU8ammjQJgqwkPBAEJUFQFgTt6KjnkiTIC+5kRgYMGg/zguBEcOhKwnfdERzeJOGlbHqLw0Gb3q4gfEkC/vkAQj8k3EkFDQkPIwdW0H73IWHB3ilPD/0FN3MeNCoLaeLVxR7U7+cSwYLj+aigffBTPZQga9K9P1G6uaDlrkmO1YTIuyrJWLCaUxsnQctdVe91uLucBJcE+zrH11YxcqwqqAlcEBZ19XWsa8ix1iFYUe+HdW9JEjwOBkNZ4xj9wTVy1pmhejWP6W5mD3Zz4wbfjiDqp5iobyavuSRBfozjtBfvmDsZqwVCsGG2QPBVu0CiOp9Z1zn5A4+Z2cU/uLvTQfG5XbubZSYKubdpE7n+WoRtI+g3nLAw7I+A2v1W0C+pSzxN7CrekGCLDSPfr8wIDkeqM41ccrgwrqmJ1Dioq00QXKktpP5SPk/vuajmNHFkpmL/oA+dK4VLW8TlSQAAAABJRU5ErkJggg==" />
                : <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoklEQVR4nO2WS0vDQBDH90t48IlHDwraHR9o25lKL4KKH0J2NtboZ/GmKB48evfxTVrxrtSb1ZstyqYtFmmS3U2LPXRgIIQkv/znuUKMbdRMqvKcVBgC46NkrIHGT+PRdfveMQSF2YEBN3h7WjJeSI1N0PSd6IwtYLpdZ5zPpjLAA2D6SAX+campAbq07wUFptNIgSO0V73UdOKhFP2hvaG3Vb52iDM+4U0K+0qlPJWulul6UNBf5XSZ3jI21dtVw7S1qhCAsZ6sGpsmkglgDF2U5IKSNO+BxsU0OGiqxIM1PTgWT91A7eB4FwsGpmeP/L1JVVzqRGwBNL30TwvWkhQ3+n48KG0KS4tyHlPdwhVsimi4YManrKGWTK8xz1ZHr7ikWW3Daiemo1iw2adOA0Rh3mqAMH0lDpDO31855znV8VxYLf64tvJwyfguVWEyFdxWXdxxCXlC8bVAFfesoF0zSzzzQUBh6ATtmlniPmE34c1p3BVZbDnMTwDjmalMK5VMN9Y5tT6ZaKpIpnszhaJTSvukUjXDwfRpasuMTfyD/QBHBh3vjlC6CQAAAABJRU5ErkJggg==" /> 
            } */}
            {/* <Link to={props.link} className="room-link">Lien</Link> */}
            {/* </Link> */}
        </div>
    );
}

export default RoomCard;
