import React from 'react';
import download from '../img/download.svg'

const Download = (props) => {
  return (
    <div className="certificate-card">
      <div className="certificate-info">
        <div className="certificate-title">{props.title}</div>
      </div>
      <button className="download-button" onClick={props.onDownloadClick}>
        <img className="button-icon-image-download" src={download} alt="Button" />
      </button>
    </div>
  );
};

export default Download;
